import { useState } from 'react';
import Menu from '@mui/material/Menu';
import { MenuItem, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Box, { BoxProps } from '@mui/material/Box';

interface SelectPickerProps extends BoxProps {
  items: SelectPickerItem[];
  text?: string | React.ReactElement;
  showCaret?: boolean;
  caretPosition?: 'left' | 'right';
  disabled?: boolean;
  dataTestId?: string;
}

export interface SelectPickerItem {
  icon?: React.ReactElement;
  label?: string;
  onClick?: () => any;
  isDisabled?: boolean;
}

export const SelectPicker = ({
  items,
  text,
  sx,
  dataTestId,
  showCaret = true,
  caretPosition = 'right',
  disabled,
  ...props
}: SelectPickerProps) => {
  const theme = useTheme();
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  const onItemClick = (event: React.MouseEvent, item: SelectPickerItem) => {
    event.stopPropagation();
    setMenuAnchorEl(null);
    item.onClick?.();
  };

  const handleMenuClose = (event: React.MouseEvent) => {
    event.stopPropagation();
    setMenuAnchorEl(null);
  };

  return (
    <>
      <Box
        display="flex"
        data-testid={dataTestId}
        onClick={(e) => {
          e.stopPropagation();
          if (disabled) return;

          setMenuAnchorEl(e.currentTarget);
        }}
        sx={{
          cursor: disabled ? 'default' : 'pointer',
          alignItems: 'center',
          fontWeight: 600,
          whiteSpace: 'nowrap',
          transition: 'all 0.3s',
          color: (theme) => theme.palette.primary.main,
          '&:hover': {
            color: (theme) => theme.palette.primary.dark,
          },
          opacity: disabled ? 0.4 : 1,
          ...sx,
        }}
        {...props}
      >
        {showCaret && caretPosition === 'left' && <ArrowDropDownIcon />}
        {text}
        {showCaret && caretPosition === 'right' && <ArrowDropDownIcon />}
      </Box>

      {Boolean(menuAnchorEl) && (
        <Menu anchorEl={menuAnchorEl} open onClose={handleMenuClose}>
          {items.map((item, index) => (
            <MenuItem
              key={item.label}
              onClick={(event) => onItemClick(event, item)}
              style={{ color: theme.palette.grey[500] }}
              disabled={item.isDisabled}
            >
              <Typography sx={{ color: 'text.primary' }}>{item.label}</Typography>
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
};

import { createMap } from '@/common/utils/map';
import { groupBy, sumBy, uniq, uniqBy } from 'lodash';
import { Drop, DropType, Load } from '@/web-types';
import { isPresent } from 'ts-is-present';
import { Subset } from '@/common/types/subset';
import { batchSorter, batchLoadsSorter } from '@/common/utils/sorters';

export const getBatchFeedingData = (_allDrops: Subset<Drop>[], _allLoads: Subset<Load>[]) => {
  const allDrops = _allDrops.map((d) => ({ ...d, calledLbs: d.desiredCalledLbs ?? d.calledLbs })).sort(batchSorter);
  const rationsMap = createMap(_allDrops.map((d) => d.ration).filter(isPresent), (r) => r.id!);
  const pensMap = createMap(allDrops.map((d) => d.pen).filter(isPresent), (p) => p.id!);
  const loadsMap = createMap(_allLoads, (l) => l.id);
  const dropsByRationId = groupBy(allDrops, (d) => d.ration?.id);
  const dropsByPenId = groupBy(allDrops, (d) => d.pen?.id);

  return [...rationsMap]
    .map(([rationId, ration]) => {
      const drops = dropsByRationId[rationId];
      const batchDrops = drops.filter((d) => d.type === DropType.Batch);
      const _callFeedDrops = drops.filter((d) => d.type === DropType.CallFeed);

      const callFeedDrops = _callFeedDrops.map((d) => {
        const childDrops = batchDrops?.filter((bd) => bd.parentDropId === d.id);
        const fedLbs = sumBy(childDrops, (cd) => cd.fedLbs ?? 0);
        const remainingLbs = (d.calledLbs ?? 0) - fedLbs;
        const absRemainingLbs = remainingLbs < 0 ? 0 : remainingLbs;
        const dropIndex =
          _callFeedDrops.filter((cfd) => cfd.pen?.id === d.pen?.id).findIndex((cfd) => cfd.id === d.id) + 1;

        return {
          ...d,
          remainingLbs,
          absRemainingLbs,
          dropIndex,
        };
      }).sort(batchSorter);

      return {
        ...ration,
        // Stats for the ration
        fedLbs: sumBy(batchDrops, (d) => d.fedLbs ?? 0),
        remainingLbs: sumBy(callFeedDrops, (d) => d.remainingLbs),
        absRemainingLbs: sumBy(callFeedDrops, (d) => d.absRemainingLbs),
        calledLbs: sumBy(callFeedDrops, (d) => d.calledLbs ?? 0),
        drops,
        batchDrops,
        callFeedDrops,

        // Loads for the ration
        loads: uniq(drops.map((d) => d.loadId).filter(isPresent))
          .map((loadId) => loadsMap.get(loadId)!)
          .map((load) => {
            const loadDrops = batchDrops.filter((d) => d.loadId === load.id);
            return {
              ...load,
              pens: loadDrops.map((d) => pensMap.get(d.pen?.id!)!),
            };
          })
          .sort(batchLoadsSorter),

        // Pens for the ration
        pens: uniqBy(drops.map((d) => d.pen).filter(isPresent), (p) => p.id).map((p) => {
          const drops = dropsByPenId[p.id!];
          const batchDrops = drops.filter((d) => d.type === DropType.Batch);
          const callFeedDrops = drops.filter((d) => d.type === DropType.CallFeed);
          const fedLbs = sumBy(batchDrops, (d) => d.fedLbs ?? 0);
          const calledLbs = sumBy(callFeedDrops, (d) => d.calledLbs ?? 0);
          const remainingLbs = calledLbs - fedLbs;
          const completedPct = calledLbs ? fedLbs / calledLbs : 0;
          return {
            ...p,
            dropsCount: callFeedDrops.length,
            fedLbs,
            calledLbs,
            remainingLbs,
            completedPct,
          };
        }),
      };
    })
    .filter(isPresent);
};

import Box, { BoxProps } from '@mui/material/Box';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { Ration } from '@/web-types';
import { Subset } from '@/common/types/subset';
import { styled } from '@mui/material/styles';

interface RationColorBoxProps extends BoxProps {
  ration?: Subset<Ration> | null;
  nameSx?: TypographyProps['sx'];
}

const RationColorBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'color',
})<{ color: string }>(({ theme, color }) => ({
  minWidth: 14,
  width: 14,
  minHeight: 14,
  height: 14,
  display: 'inline-block',
  background: color,
  marginRight: 10,
  borderRadius: 2,
}));

export const RationWithColorTag = ({ ration, nameSx, ...props }: RationColorBoxProps) => {
  return (
    <Box display="inline-flex" alignItems="center" {...props}>
      {ration?.color && <RationColorBox color={ration.color} />}
      <Typography lineHeight={1} sx={nameSx}>
        {ration?.name || '...'}
      </Typography>
    </Box>
  );
};
